<template>
	<div id="exam2">
		<index>
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>教务</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">成绩管理</a></el-breadcrumb-item>
			</el-breadcrumb>

      <v-weburl-iframe class="content" url="teaching/score" />
		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue'
import VWeburlIframe from "@/components/weburl-iframe";
export default {
	components: {
    VWeburlIframe,
		index
	},
};
</script>

<style lang="scss" scoped>

</style>
